<template>
  <div>
    <query-form class="content_block" label-width="120px" @search="handleSearch" :span="8" @reset="handleReset">
      <el-form-item label="仓库类型编码：" prop="code">
        <el-input v-model="form.code" placeholder="请输入仓库类型编码"></el-input>
      </el-form-item>
      <el-form-item label="仓库类型名称：" prop="name">
        <el-input v-model="form.name" placeholder="请输入仓库类型名称"></el-input>
      </el-form-item>
      <el-form-item label="创建日期：">
        <el-date-picker
          style="width: 100%"
          v-model="form.dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
    </query-form>
    <table-with-pagination
      :tableData="customerList"
      class="content_block"
      :columnList="columnList"
      :pagination.sync="pagination"
      @current-change="getTableList"
      @sort-change="getTableList"
      :leftToolbar="tableButtons"
    />
    <el-dialog :title="`${addType === 1 ? '新增' : '编辑'}仓库类型`" :visible.sync="addStockDialog" width="400px">
      <el-form :model="addForm" label-width="120px" :rules="rules" ref="addFormRef">
        <el-form-item v-if="addType === 2" label="仓库类型编码：" prop="code">
          <el-input v-model="addForm.code" disabled></el-input>
        </el-form-item>
        <el-form-item label="仓库类型名称：" prop="name">
          <el-input v-model="addForm.name" placeholder="请输入仓库类型名称" :maxlength="30"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelStockHandle">取 消</el-button>
        <el-button type="primary" @click="addStockHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import _ from 'lodash';
import { getInventoryOutTypeList, addWarehouseType, editWarehouseType } from '@/api/stock.js';
import { dateFormat } from '@/utils/dateFormat.js';

export default {
  name: 'STOCKIN_RECORD',
  mixins: [TablePaginationMixin],
  data () {
    return {
      rules: {
        name: [{ required: true, trigger: 'blur', message: '请输入仓库类型名称' }],
      },
      addStockDialog: false,
      addForm: {
        id: undefined,
        name: '',
        code: '',
      },
      addType: 1, // 1新增， 2编辑
      tableButtons: [
        {
          name: '新增',
          status: 'primary',
          size: 'small',
          permission: 'sparePart:stock:type:add',
          click: () => {
            this.addType = 1;
            this.addForm.name = '';
            this.addStockDialog = true;
          },
        },
      ],
      form: {
        code: '',
        name: '',
        dateRange: [],
        startTime: '',
        endTime: '',
      },
      curRow: {},
      customerList: [],
      columnList: [
        {
          tooltip: true,
          label: '仓库类型编码',
          minWidth: '180px',
          prop: 'code',
        },
        {
          tooltip: true,
          label: '仓库类型名称',
          minWidth: '180px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '创建时间',
          minWidth: '180px',
          prop: 'createTime',
          format: (val) => val && dateFormat(val).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          tooltip: true,
          label: '最后操作时间',
          minWidth: '180px',
          prop: 'lastUpdateTime',
          format: (val) => val && dateFormat(val).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          label: '操作',
          minWidth: '180px',
          prop: 'actions',
          render: (h, { row }) => {
            let actions = [];
            actions.push(
              <span
                v-auth={'sparePart:stock:type:update'}
                class='cursor-pointer text-primary ml-1 '
                onClick={this.openBackDialog.bind(this, row)}
              >
                  编辑
              </span>,
            );
            return actions;
          },
        },
      ],
    };
  },
  methods: {
    cancelStockHandle () {
      this.addStockDialog = false;
      this.$refs.addFormRef && this.$refs.addFormRef.resetFields();
    },
    addStockHandle () {
      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          const rs
              = this.addType === 1
                ? await addWarehouseType({ name: this.addForm.name })
                : await editWarehouseType({ ...this.addForm });
          if (rs.heads.code === 200) {
            this.$message.success(`${this.addType === 1 ? '新增' : '编辑'}仓库类型成功`);
            this.cancelStockHandle();
            this.handleSearch();
          }
        }
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      Object.keys(this.form).forEach((i) => {
        this.form[i] = '';
      });
      this.form.dateRange = [];
      this.handleSearch();
    },
    async getTableList () {
      if (this.form.dateRange.length) {
        this.form.startTime = +new Date(this.form.dateRange[0]);
        this.form.endTime = +new Date(this.form.dateRange[1]);
      }
      const param = {
        ...this.form,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        orderBy: [
          {
            orderByProperty: 'createTime',
            orderByType: 'DESC',
          },
        ],
      };
      const rs = await getInventoryOutTypeList(param);
      if (rs.heads.code === 200) {
        const { list, total } = rs.body;
        this.customerList = list;
        this.pagination.total = total;
      }
    },
    openBackDialog (row) {
      this.curRow = _.cloneDeep(row);
      this.addForm.id = this.curRow.id;
      this.addForm.name = this.curRow.name;
      this.addForm.code = this.curRow.code;
      this.addType = 2;
      this.addStockDialog = true;
    },
  },
};
</script>
